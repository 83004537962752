import React from "react"
import { Link } from "gatsby"

const SubjectLink = ({ post }) => (
  <Link to={post.frontmatter.path}>
    <article className="card ">
      <header>
        <h2 className="post-title">
          <Link to={post.frontmatter.path} className="post-link">
            {post.frontmatter.title}
          </Link>
        </h2>
      </header>
    </article>
  </Link>
)
export default SubjectLink
